import { useEffect, useMemo } from 'react'

import Head from 'next/head'
import useMediaQuery from '@mui/material/useMediaQuery'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import mixpanel from 'mixpanel-browser'
import CssBaseline from '@mui/material/CssBaseline'

import { Provider as AuthProvider } from 'contexts/authentication'
import { useService, Script as Analytics } from 'services/analytics'
import { useSetUpdateAvailable } from 'stores/UpdateStore'
import * as Internationalization from 'contexts/internationalization'

/* Our Custom CSS */
import '../../styles/globals.css'
import '../../styles/react-spring-bottom-sheet.css'
import '../../styles/table.css'

/* Theme variables */
import '../theme/variables.css'
import NonSSRWrapper from 'components/NonSSRWrapper'

import 'react-swipeable-list/dist/styles.css'

export default function App({ Component, pageProps }) {
    useService()
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
    const setUpdateAvailable = useSetUpdateAvailable()

    const theme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: prefersDarkMode ? 'dark' : 'light',
                },
            }),
        [prefersDarkMode]
    )

    useEffect(() => {
        if (
            typeof window !== 'undefined' &&
            'serviceWorker' in navigator &&
            window.workbox !== undefined
        ) {
            const wb = window.workbox
            // add event listeners to handle any of PWA lifecycle event
            // https://developers.google.com/web/tools/workbox/reference-docs/latest/module-workbox-window.Workbox#events
            // TODO: hide these once done dev
            // wb.addEventListener('installed', event => {
            //     console.log(`Event ${event.type} is triggered.`)
            //     console.log(event)
            // })

            // wb.addEventListener('controlling', event => {
            //     console.log(`Event ${event.type} is triggered.`)
            //     console.log(event)
            // })

            // wb.addEventListener('activated', event => {
            //     console.log(`Event ${event.type} is triggered.`)
            //     console.log(event)
            // })

            // A common UX pattern for progressive web apps is to show a banner when a service worker has updated and waiting to install.
            // NOTE: MUST set skipWaiting to false in next.config.js pwa object
            // https://developers.google.com/web/tools/workbox/guides/advanced-recipes#offer_a_page_reload_for_users
            const promptNewVersionAvailable = event => {
                // `event.wasWaitingBeforeRegister` will be false if this is the first time the updated service worker is waiting.
                // When `event.wasWaitingBeforeRegister` is true, a previously updated service worker is still waiting.
                // You may want to customize the UI prompt accordingly.
                wb.addEventListener('controlling', event => {
                    // See file history: we are removing the prompt and forcing an auto reload+update since this is what happens all the time anyway
                    setUpdateAvailable(true)
                    window.location.reload()
                })

                // Send a message to the waiting service worker, instructing it to activate.
                wb.messageSkipWaiting()
            }

            wb.addEventListener('waiting', promptNewVersionAvailable)

            // never forget to call register as auto register is turned off in next.config.js
            wb.register()
        }
    }, [])

    useEffect(() => {
        mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN || '', {
            // Enable these in DEV mode to see console entries for Mixpanel requests
            // debug: true,
            // ignore_dnt: true,
        })

        process.env.NEXT_PUBLIC_MIXPANEL_TOKEN && mixpanel.register({ platform: 'pwa' })
    }, [])

    return (
        <>
            <Head>
                <meta
                    name="viewport"
                    content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no,viewport-fit=cover"
                />
                <title>Avalanche Canada</title>
                <meta charSet="utf-8" />
                <meta httpEquiv="X-UA-Compatible" content="IE=edge" />

                <meta name="description" content="Avalanche Canada Mobile App" />
                <meta property="og:title" content="Avalanche Canada Mobile App" />
                <meta
                    content="https://res.cloudinary.com/avalanche-ca/image/upload/bo_20px_solid_rgb:fff,c_pad,h_315,w_600/v1413919754/logos/avalanche_canada_left_quqmls.jpg"
                    property="og:image"></meta>

                <link rel="manifest" href="/manifest.json" />
                <link
                    rel="icon"
                    type="image/png"
                    href="https://avalanche.ca/png/logo72.png"
                    sizes="72x72"></link>
                <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
                <meta name="apple-mobile-web-app-status-bar" content="#fff" />
                <meta name="theme-color" content="#fff" media="(prefers-color-scheme: light)" />
                <meta name="theme-color" content="#000" media="(prefers-color-scheme: dark)" />
            </Head>
            <ThemeProvider theme={theme}>
                <Internationalization.Provider>
                    <AuthProvider>
                        <CssBaseline />
                        <div suppressHydrationWarning>
                            <NonSSRWrapper>
                                {typeof window === 'undefined' ? null : (
                                    <Component {...pageProps} />
                                )}
                            </NonSSRWrapper>
                        </div>
                    </AuthProvider>
                    <Analytics />
                </Internationalization.Provider>
            </ThemeProvider>
        </>
    )
}
