import * as React from 'react'

import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import { supported } from 'utils/mapbox'
import { noop } from '@avalanche-canada/utils/function'

// From: https://developers.google.com/analytics/devguides/collection/analyticsjs/events
export function handleOutboundSponsorClick(event) {
    navigation('Outbound Sponsor', event.currentTarget.href)
}

export function handleForecastTabActivate(index) {
    navigation('Forecast Tab activation', HEADERS[index])
}

useService.propTypes = {
    children: PropTypes.element.isRequired,
}

export function useService() {
    const { asPath } = useRouter()
    React.useEffect(() => {
        ga('set', 'transport', 'beacon')
        ga('set', MAPBOXGL_SUPPORTED, supported().toString())
    }, [])
    React.useEffect(() => {
        ga('send', 'pageview', asPath)
    }, [asPath])
}

export function Script() {
    return (
        <React.Fragment>
            <GoogleAnalytics />
        </React.Fragment>
    )
}

function GoogleAnalytics() {
    const script = {
        __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALITICS_ID}');
        `,
    }

    return (
        <React.Fragment>
            <script dangerouslySetInnerHTML={script}></script>
            <script async src={process.env.NEXT_PUBLIC_GOOGLE_ANALITICS_SRC}></script>
        </React.Fragment>
    )
}


// Utils and constants
// TODO Remove the second noop
const ga = typeof window === 'undefined' ? noop : window.ga || noop
const HEADERS = ['Danger ratings', 'Problems', 'Details']
const MAPBOXGL_SUPPORTED = 'dimension1'
function navigation(...args) {
    ga('send', 'event', 'Navigation', ...args)
}
