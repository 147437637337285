import { IntlProvider } from 'react-intl'

import { noop } from '@avalanche-canada/utils/function'

import Messages from 'services/intl/messages'
import { useLocale } from 'stores/SettingsStore'

export function Provider({ children }) {
    const locale = useLocale()
    const messages = Messages.get(locale)

    return (
        <IntlProvider
            onError={noop}
            key={locale}
            locale={locale + '-CA'}
            messages={messages}
            defaultLocale="en-CA">
            {children}
        </IntlProvider>
    )
}
