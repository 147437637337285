import * as React from 'react'

import * as Auth0 from '@auth0/auth0-react'
import { FormattedMessage } from 'react-intl'

import * as Config from 'services/auth/config'
import * as Sentry from 'services/sentry'

export function Provider({ children }) {
    const redirectUri = process.env.NEXT_PUBLIC_SITE_ORIGIN_URL
    const handleRedirectCallback = React.useCallback(() => {})

    return (
        <Auth0.Auth0Provider
            domain={Config.domain}
            clientId={Config.clientId}
            audience={Config.audience}
            redirectUri={redirectUri}
            cacheLocation="localstorage"
            onRedirectCallback={handleRedirectCallback}>
            {children}
            <UserListener />
        </Auth0.Auth0Provider>
    )
}

// Hooks
export function useAuth() {
    return Auth0.useAuth0()
}
export function useLogin() {
    const { loginWithRedirect, loginWithPopup } = useAuth()

    if (window.isWKWebView) {
        // WKWebView (iOS Swift app) doesn't support popup mode—search the Swift code for where this boolean flag is set: `window.isWKWebView = true;`
        return loginWithRedirect
    } else {
        return loginWithPopup
    }
}
export function useLoading() {
    const { isLoading } = useAuth()

    return isLoading
}
export function useUsername() {
    const { user } = useAuth()

    return user?.user_metadata?.nickname || user?.nickname
}
export function useUserAvatar() {
    const { user } = useAuth()

    return user?.picture
}

export function withAuthenticationRequired(Component, options = {}) {
    return Auth0.withAuthenticationRequired(Component, {
        onRedirecting() {
            return (
                <FormattedMessage
                    id="veryfyingAuth"
                    defaultMessage="Verifying authentication..."
                    description="Checking authentication"
                />
            )
        },
        ...options,
    })
}

// Util
function UserListener() {
    const { user } = useAuth()

    React.useEffect(() => {
        if (user) {
            Sentry.setUserContext(user)
        }
    }, [user])

    return null
}
